<template>
  <v-app id="home" class="custom-page">
    <div class="flex-container">

      <div class="block block1" id="propos">
       <div >
    <v-row class="nav-buttons" align="center">
      <div class="mr-4">
        <v-img
          src="https://www.qorani.fr/wp-content/uploads/go-x/u/0ac90fa0-d150-45b6-87c1-7d5cd082b2bb/image.png"
          alt="QORANI Logo"
          contain
          max-height="30"
          max-width="30"
        ></v-img>
      </div>
        
      <h1>
        <span style="color: black;">Qorani</span>
        <span style="color: blue;">PRO</span>
      </h1>
    
        <v-col cols="auto" class="nav-button">
          <button :class="{ 'active-link': activeSection === 'propos' }" class="normal-case black-text" text @click="scrollToSection('propos')">À propos de nous</button>
        </v-col>
        <v-col cols="auto" class="nav-button">
          <button :class="{ 'active-link': activeSection === 'login' }" class="normal-case black-text" text @click="scrollToSection('login')">Nos offres</button>
        </v-col>
        <v-col cols="auto" class="nav-button">
          <button :class="{ 'active-link': activeSection === 'contact' }" class="normal-case black-text" text @click="scrollToSection('contact')">Aide</button>
        </v-col>
         <v-col cols="auto" class="login-button">
            <v-btn class="green-button" text @click="scrollToSection('login')">Se connecter</v-btn>
          </v-col>
      
      </v-row>
      <v-spacer></v-spacer>
    </div> 

   
      <v-container class="text-center">
      <v-row class="align-center justify-center">
        <v-col class="d-flex flex-column align-center" cols="12" md="6">
          <h1 class="hero-title">Vos ouvrages <br/>au plus près des musulman(e)s</h1>
          <p class="hero-text">
  Rejoignez 
  <span class="hero-title2">
    <span style="color: black;">Qorani</span>
    <span style="color: blue;">PRO</span>
  </span> 
  et proposez vos livres aux musulman(e)s francophones grâce à un référencement unique sur <span class="qorani">Qorani</span>, une application mobile qui réunit plus de 23.000 utilisateurs.
</p>
          <div class="button-group">
            <v-btn color="#2ECC71" dark large href="https://www.qorani.fr/" class="hero-btn">
              Rejoindre &nbsp;<v-icon left>mdi-arrow-right</v-icon>
            </v-btn>
        
            <v-btn 
  class="no-shadow" 
  style="background-color: white; color: black;" 
  size="large" 
  block
  href="#" 
  @click.prevent="scrollToSection('contact')"
>
  Contacter notre équipe 
</v-btn>

          </div>
        </v-col>
        <v-col class="d-flex align-center justify-center" cols="12" md="6">
          <v-img
            contain
            src="@/assets/images/home.png"
            class="large-img"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    </div>
      </div>

      <!-------------------->
      <div class="block block2">   
            <v-container class="text-center">
      <v-row class="align-center justify-center">
        <v-col class="d-flex flex-column align-center" cols="12" md="6">
          <h1 class="hero-title">Qorani, le Coran maintenant !</h1>
          <p style="font-weight: bold;" class="hero-text">
Qorani est la toute nouvelle application mobile spécialisée dans la traduction et l’explication du Coran. Elle s’adresse aux musulman(e)s francophone et les aide à explorer le Coran de manière simple et accessible à tout le monde, et ce, à l’aides de fonctionnalités totalement inédites</p>
          <div class="button-group">
            
             <v-btn color="white"  large href="#" class="btn_telecharger">Télécharger Qorani</v-btn>
             <v-btn color="#2ECC71" dark large href="#" class="hero-btn">
              Découvrir Qorani
            </v-btn>
          </div>
        </v-col>

        <v-col class="d-flex align-center justify-center" cols="12" md="6">
          <v-img
            contain
            src="@/assets/images/tel1.png"
            class="large-img"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>        
      </div>

      <!-------------------->
      <div class="block block3">  
  <v-container class="text-center">
    <v-row class="align-center justify-center">
      <v-col class="d-flex align-center justify-center" cols="12" md="6">
        <v-img
          contain
          src="@/assets/images/page3.png"
          class="large-img"
        ></v-img>
      </v-col>

      <v-col class="d-flex flex-column align-center" cols="12" md="6">
        <h1 class="hero-title">Une application (déjà) populaire</h1>
        <p style="font-weight: bold;" class="hero-text">
          Avec plus de 23 000 utilisateurs, Qorani est déjà utilisée par une large communauté de passionnés de savoir islamique impatiente de découvrir vos ouvrages, une opportunité unique de maximiser votre visibilité et d'accroître vos ventes via notre librairie intégrée.
        </p>     
        <v-btn color="#2ECC71" dark large href="#" class="hero-btn">
          Découvrir Qorani
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</div>
    <!-------------------->
      <div class="block block4">
  <v-container class="text-center">
    <v-row class="align-center justify-center">
      <v-col class="d-flex align-center justify-center" cols="12" md="6">
        <v-img
          contain
          src="@/assets/images/page4.png"
          class="large-img"
        ></v-img>
      </v-col>

      <v-col class="d-flex flex-column align-center" cols="12" md="6">
        <h1 class="hero-title">Accès à une audience ciblée</h1>
        <p style="font-weight: bold;" class="hero-text">
          Via QoraniPRO, notre application permet aux utilisateurs de sélectionner leurs sujets d'intérêt et leur niveau de connaissance, garantissant ainsi que vos ouvrages soient présentés à des lecteurs véritablement intéressés par vos ouvrages.
        </p>     
        <v-btn color="#2ECC71" dark large href="#" class="hero-btn">
          Rejoindre&nbsp;<v-icon left>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</div>
 <!-------------------->
      <div class="block block5">
          <v-container class="text-center">
      <v-row class="align-center justify-center">
        <v-col class="d-flex flex-column align-center" cols="12" md="6">
          <h1 class="hero-title">Augmentation des ventes </h1>
          <p  class="hero-text">
En rejoignant QoraniPRO, vous intégrerez vos ouvrages dans notre plateforme et bénéficierez de recommandations personnalisées pour chaque utilisateur. Cela se traduit par une meilleure correspondance entre l'offre et la demande, augmentant ainsi la probabilité d'achat.</p>     
     
            
             <v-btn color="#2ECC71" dark large href="#" class="hero-btn">
             Rejoindre&nbsp;<v-icon left>mdi-arrow-right</v-icon>
            </v-btn>
       
        </v-col>

        <v-col class="d-flex align-center justify-center" cols="12" md="6">
          <v-img
            contain
            src="@/assets/images/page5.png"
            class="large-img"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    
      </div>
      
<!-------------------->

      <div class="block block7" id="login">
  <v-container class="text-center">
    <v-row class="align-center justify-center">
      <v-col class="d-flex align-center justify-center" cols="12" md="6">
        <v-img
          contain
          src="@/assets/images/page6.png"
          class="large-img6"
        ></v-img>
      </v-col>

      <v-col class="d-flex flex-column align-center" cols="12" md="6">
        <h1 class="hero-title">Connectez-vous</h1>
        <p class="hero-text">
          Saisissez votre adresse e-mail et votre mot de passe pour accéder à votre compte.
        </p>  

        <div>
          <v-card
  class="mx-auto pa-12 pb-8 no-shadow"
  max-width="448"
>
            <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"style="color: black;">
              E-mail*
            </div>

            <v-text-field
            v-model="email"
              placeholder="Votre adresse e-mail"
              class="grey-border"
              hide-details
             solo
            ></v-text-field>

            <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"style="color: black;">
              Mot de passe*
            </div>

            <v-text-field
            v-model="password"
              placeholder="Votre mot de passe"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="
                      !isPasswordVisible
                        ? icons.mdiEyeOffOutline
                        : icons.mdiEyeOutline
                    "
                    hide-details
                    @click:append="isPasswordVisible = !isPasswordVisible"
              class="grey-border"
              solo
            ></v-text-field>

                       <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"style="color: black;">

  <a @click="dialogForgetPWD = true"  class="hero-link" style="color: black; font-weight: bold;padding-bottom:30px;">Mot de passe oublié ?</a>
</div>

            
            <v-btn
  class="mb-8"style="background-color: #2ECC71; color: white;"  
  size="large"
  variant="tonal"
  block
  @click="loginToQorani"
>
  Se connecter
</v-btn>

            <p class="mt-4" style="color: black;">
  Nouveau sur QoraniPRO ? 
  <a href="#" class="hero-link" style="color: black; font-weight: bold;" @click.prevent="scrollToSection('inscrire')">
    Créez un compte
  </a>
</p>

          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</div>
<!-------- dialog ------------>
<v-row justify="center">
      <v-dialog v-model="dialogForgetPWD" max-width="600">
        <v-card color="text-center">
          <v-card-text class="d-flex flex-column justify-center align-center">
            <v-avatar color="primary" class="mt-10" icon size="50">
              <v-icon size="2rem" color="white">
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </v-avatar>
            <h6 class="text-xl mt-4 font-weight-small">
              Veuillez entrer votre adresse e-mail pour rechercher votre compte.
            </h6>
          </v-card-text>

          <v-card-text class="d-flex align-center">
            <v-text-field
              prepend-inner-icon="mdi-account-outline"
              outlined
              v-model="email"
              label="Email"
              placeholder="nomprenom@gmail.com"
              hide-details
              class="mb-3"
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-btn
              color="primary"
              class="mt-4"
              style="background-color: #A8FE39; color:#0B0B0B"
              @click="resetPassword"
            >
              Envoyer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    
  <!-------------------->
  <div class="block block8" id="inscrire">
    <v-container class="text-center">
      <v-row class="align-center justify-center">
        <v-col class="d-flex flex-column align-center" cols="12" md="6">
          <h1 class="hero-title">Créez votre compte</h1>

          <div>
            <v-card
              class="mx-auto pa-2 pb-6 no-shadow"
              max-width="448"
              style="background-color:rgba(255, 255, 255, .0)"
            >
              <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between" style="color: black;">
                Téléphone portable*
              </div>
              <v-text-field
                v-model="numTel"
                placeholder="Entrez votre numéro"
                class="grey-border"
                type="tel"
                solo
              ></v-text-field>

              <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between" style="color: black;">
                E-mail*
              </div>
              <v-text-field
                v-model="email"
                placeholder="Votre adresse e-mail"
                class="grey-border"
                solo
              ></v-text-field>

              <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between" style="color: black;">
                Mot de passe*
              </div>
              <v-text-field
                v-model="newPassword"
                placeholder="Votre mot de passe"
                class="grey-border"
                solo
              ></v-text-field>

              <v-checkbox
                v-model="accepted"
                label="J’accepte les conditions générales d’utilisation de QoraniPRO"
                class="mb-4 black-label"
              ></v-checkbox>

              <v-btn
                class="mb-8"
                style="background-color: #2ECC71; color: white;"
                size="large"
                variant="tonal"
                block
                @click="InscriptionToQorani"
              >
                Créez un compte
              </v-btn>

              <p class="mt-4" style="color: black;">
                Déjà inscrit sur QoraniPRO ?  
                <a href="#" class="hero-link" style="color: black; font-weight: bold;" @click.prevent="scrollToSection('login')">Connectez-vous</a>
              </p>
            </v-card>
        </div>
       
        </v-col>

        <v-col class="d-flex align-center justify-center" cols="12" md="6">
          
        </v-col>
      </v-row>
    </v-container>
      
      </div>
      <!---------------    bloc contact  ------------------->

      <div class="block block9" id="contact">
    <v-container class="text-center">
      <v-row class="align-center justify-center">
        <v-col class="d-flex align-center justify-center" cols="12" md="6">
          <v-img
            contain
            src="@/assets/images/page9.png"
            class="large-img6"
          ></v-img>
        </v-col>

        <v-col class="d-flex flex-column align-center" cols="12" md="6">
          <h1 class="hero-title">Contactez-Nous</h1>

          <v-card class="mx-auto pa-12 pb-8 no-shadow" max-width="448">
            <div
              class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
              style="color: black;"
            >
              Name*
            </div>

            <v-text-field
              v-model="name"
              placeholder="Votre nom"
              class="grey-border"
              hide-details
              solo
            ></v-text-field>

            <div
              class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
              style="color: black;"
            >
              Email*
            </div>

            <v-text-field
              v-model="emailcontact"
              placeholder="Votre email"
              class="grey-border"
              hide-details
              solo
            ></v-text-field>

            <div
              class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
              style="color: black;"
            >
              Subject*
            </div>

            <v-text-field
              v-model="subject"
              placeholder="Votre sujet"
              class="grey-border"
              hide-details
              solo
            ></v-text-field>

            <div
              class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
              style="color: black;"
            >
              Message*
            </div>

            <v-textarea
              v-model="message"
              placeholder="Votre message"
              hide-details
              solo
            ></v-textarea>

            <div style="padding-top: 30px">
              <v-btn
                class="mb-8"
                style="background-color: #2ECC71; color: white;"
                size="large"
                variant="tonal"
                block
                @click="contacterToQorani"
              >
                se soumettre
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
<!------------  footer -------->
<div class="block block6" id="footer">
  <v-footer>
    <div class="footer-content">
      <v-row justify="space-between" align="center">
        <v-col class="text-left">
          <h1>
            <span style="color: black;">Qorani</span>
            <span style="color: blue;">PRO</span>
          </h1>
        </v-col>
        <v-col class="text-right">
          <p style="color: black; margin-left: 800px;">contact@qoranipro.fr</p>
        </v-col>
      </v-row>
      <div class="footer-text text-center">
       <v-col class="text-left">
        <p style="color: black;">Ensemble, diffusons le savoir.</p>
      </v-col></div>
      <div class="footer-icons text-center">
      <v-col class="text-left">
        <v-icon color="black" href="#">mdi-facebook</v-icon>
        <v-icon color="black" href="#">mdi-instagram</v-icon>
      </v-col></div>
      <div class="footer-text text-center">
      <v-col class="text-left">
      
        <p style="color: black;">Copyright 2024 QoraniPRO</p>
      </v-col></div>
    </div>
  </v-footer>
</div>
  </v-app>
</template>

<script>
import emailjs from 'emailjs-com';
import { ref } from "@vue/composition-api";
import firebase from "firebase/compat/app";
import "firebase/auth";
import axios from "axios";
import Constant from "@/utils/constants";
import store from "../../store/index";
import {
  mdiHelpCircleOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
  
} from "@mdi/js";
export default {
  setup() {
    const passwordError = ref("");
    const newPassword = ref("");
    const dialogVisible = ref(false);

    const isPasswordVisible = ref(false);
    const numTel = ref(""); 
    const accepted = ref(false); 
    const email = ref("");
    const password = ref("");
    const dialogForgetPWD = ref(false);
    const activeSection = ref('');
    const icons = {
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiHelpCircleOutline,
    };

    const name = ref('');
    const emailcontact = ref('');
    const subject = ref('');
    const message = ref('');
    
    const contacterToQorani = async () => {
  if (!name.value || !emailcontact.value || !subject.value || !message.value) {
    alert('Please fill in all the fields.');
    return;
  }

  try {
    const templateParams = {
      name: name.value,
      emailcontact: emailcontact.value,
      subject: subject.value,
      message: message.value,
    };

    await emailjs.send(
      'service_34o25hi',        // Your service ID
      'template_z2v80s4',       // Your template ID
      templateParams,           // Object containing form values
      'LRUb9gd1lH30i77JS'       // Your public key
    );

    alert('Your message has been sent successfully!');

    // Optionally reset the form fields
    name.value = '';
    emailcontact.value = '';
    subject.value = '';
    message.value = '';
  } catch (error) {
    // console.error('Error sending message:', error);
    alert('An error occurred while sending your message.');
  }
};

  
   /* const contacterToQorani = async () => {
    console.log('contact');
    
    const emailBody = `Name: ${name.value}\nEmail: ${emailcontact.value}\n\nSubject:\n${subject.value}\n\nMessage:\n${message.value}`;
    console.log('Email Body:', emailBody);
    
    Email.send({
        Host: "smtp.elasticemail.com",
        Port:"2525",
        Username: "no_replay@qorani.fr",
        Password: "28EE3DE3F894593D52845B84E91E13539B8B",
        To: 'nadiabouzayani3@gmail.com',
        From: emailcontact.value,
        Subject: subject.value,
        Body: emailBody
    }).then(
        message => alert(message)
    ).catch(
        error => console.error('Email sending failed:', error)
    );
};*/


   
  const scrollToSection = (section) => {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        activeSection.value = section;
      }
    }
    
    async function InscriptionToQorani() {
      if (!accepted.value) {
        alert("Vous devez accepter les conditions générales pour créer un compte.");
        return;
      }

      try {
        await firebase.auth().createUserWithEmailAndPassword(email.value, newPassword.value);

        const userData = {
          email: email.value,
          numTel: numTel.value,
          role: "LIBRARY",
        };

        const response = await fetch(Constant.QORANI + "user/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        });

        if (!response.ok) {
          throw new Error("Une erreur s'est produite lors de l'inscription.");
        }

        alert("Compte créé avec succès !");
        resetForm();
        scrollToSection('login');
      } catch (error) {
        // console.error("Erreur lors de la création du compte:", error);
        alert(error.message);
      }
    }

    async function loginToQorani() {
      
      // console.log("EMAIL",email.value);
      // console.log("PASSWORD",password.value);
      try {
        // Fetch user details from your backend
        const response = await axios.get(`${Constant.QORANI}user/getByEmail/${email.value}`);
        // console.log(response);
        const userDetails = response.data;

        if (userDetails) {
          if (userDetails.role === "ADMIN" || userDetails.role === "LIBRARY") {
            await firebase.auth().signInWithEmailAndPassword(email.value, password.value);
            const userEmail = firebase.auth().currentUser.email;
            

            // Store user email in local storage or Vuex store
            localStorage.setItem("userEmail", userEmail);
            // Store user role in local storage or Vuex store
            localStorage.setItem('role', userDetails.role);
            // Store userId in local storage or Vuex store
            localStorage.setItem('ownerId',userDetails.userId);
            store.commit("setUserEmail", userEmail);

            // Redirect based on the role
            if (userDetails.role === "ADMIN") {
          this.$router.push('/Level');
          // setTimeout(() => {
          //   window.location.reload(); // Refresh the page
          // }, 10); // Delay to ensure routing is handled
        } else if (userDetails.role === "LIBRARY") {
          this.$router.push('/myLibrary');
          // setTimeout(() => {
          //   window.location.reload(); // Refresh the page
          // }, 10); // Delay to ensure routing is handled
        }
          } else {
            alert("Accès refusé. Veuillez vérifier vos informations de connexion ou contacter l'administrateur.");
          }
        } else {
          alert("Nous sommes désolés, mais l'adresse e-mail que vous avez fournie est introuvable dans notre système. Veuillez vérifier l'orthographe de votre adresse e-mail et réessayer.");
        }
      } catch (error) {
        
        alert(error);
        // console.error("An error occurred:", error);
      } finally {
        
      }

    }
    function verifyPasswordAndEmail() {
      const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email format regular expression
      const minLength = 8;
      const uppercaseRegex = /[A-Z]/;
      const specialCharsRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-="']/;
      const digitRegex = /\d/;

      if (!email.value) {
        passwordError.value = "E-mail est requis.";
        valid.value = false;
        showDialog();
      } else if (!emailFormatRegex.test(email.value)) {
        passwordError.value = "Format e-mail invalide.";
        valid.value = false;
        showDialog();
      } else if (!newPassword.value) {
        passwordError.value = "Mot de passe requis.";
        valid.value = false;
        showDialog();
      } else if (newPassword.value.length < minLength) {
        passwordError.value = "Le mot de passe doit comporter au moins 8 caractères.";
        valid.value = false;
        showDialog();
      } else if (!uppercaseRegex.test(newPassword.value)) {
        passwordError.value = "Le mot de passe doit contenir au moins une lettre majuscule.";
        valid.value = false;
        showDialog();
      } else if (
        !(
          specialCharsRegex.test(newPassword.value) ||
          digitRegex.test(newPassword.value)
        )
      ) {
        passwordError.value = "Le mot de passe doit contenir au moins un chiffre, un caractère spécial ou un espace.";
        valid.value = false;
        showDialog();
      } else if (newPassword.value !== cPassword.value) {
        passwordError.value = "Les mots de passe ne correspondent pas.";
        valid.value = false;
        showDialog();
      } else {
        passwordError.value = "Le mot de passe et email sont valides !";
        valid.value = true;
        showDialog();
      }
    }
    /************ouvrir alet dialog d'inscription******************* */ 
    function showDialog() {
      dialogVisible.value = true;
    }
   /************fermer alert dialog d'inscription******************* */ 
    function closeDialog() {
      dialogVisible.value = false;
    }
     /************VIDER CHAMPS INSCRIPTION******************* */ 
    function resetForm() {
      email.value = "";
      newPassword.value = "";
      numTel.value = "";
      accepted.value = false;
    }
 /************ MODIFIER MOT DE PASSE ******************* */ 
    async function resetPassword() {
      try {
        await firebase.auth().sendPasswordResetEmail(email.value);
        alert("E-mail de réinitialisation du mot de passe envoyé. Vérifiez votre boîte de réception.");
        dialogForgetPWD.value = false;
      } catch (error) {
        alert(error.message);
        dialogForgetPWD.value = false;
      }
    }
      
    return {
      email,
      password,
      scrollToSection,
      resetPassword,
      dialogForgetPWD,
      loginToQorani,
      InscriptionToQorani,
      isPasswordVisible,
      icons,
      numTel, 
      accepted,
      resetForm,
      newPassword,
      verifyPasswordAndEmail,
      showDialog,
      closeDialog,
      name,
      emailcontact,
      subject,
      message,
      contacterToQorani,
      activeSection,


    };
  
 
  }
};
</script>

<style scoped>
.flex-container {
  display: flex; 
  flex-direction: column; /* Disposer les blocs en colonne */
  padding: 0; 
  margin: 0;
}

.block {
/*height: 100vh; */
  margin: 0px; /* Espace vertical entre les blocs */
  padding: 20px;
  color: white; 
  text-align: center; 
  /* border-radius: 8px; */
  /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);  Ombre pour effet de page */
  /* width: calc(100% - 20px);  Ajuste la largeur pour tenir compte des marges */
}

.block1 {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/qorani-c5ac6.appspot.com/o/FCMImages%2FbackgroundNadia2.png?alt=media&token=43419624-9bae-4e31-a667-fa376dc04875'); 
  background-size: cover; 
  background-position: center; 

}

.block2 {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/qorani-c5ac6.appspot.com/o/FCMImages%2FbackgroundNadia2.png?alt=media&token=43419624-9bae-4e31-a667-fa376dc04875'); 
  background-size: cover; 
  background-position: center; 
}

.block3 {
  background-image: url('https://cdn.discordapp.com/attachments/1277533120765366306/1280194589256319098/back2.png?ex=66e8fdde&is=66e7ac5e&hm=adb7bd2717ff49a8c77305d71d502eaf9a3189f8601fd614c892efc79c191acc&'); 
  background-size: cover; 
  background-position: center; 
}

.block4 {
  background-image: url('https://cdn.discordapp.com/attachments/1277533120765366306/1280197981605199882/back3.png?ex=66e90107&is=66e7af87&hm=40ebf56ab900ccbb0d9909e0e8500f7865a8ddd3271f8af240344f8c433fb8ba&'); 
  background-size: cover; 
  background-position: center;
}

.block5 {
  background-color: white; /* Violet */
}

.block6 {
  background-color: white; /* Orange */
}

.block7 {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/qorani-c5ac6.appspot.com/o/FCMImages%2FbackgroundNadia2.png?alt=media&token=43419624-9bae-4e31-a667-fa376dc04875'); 
  background-size: cover; 
  background-position: center;
}
.block8{
  background-image: url('https://cdn.discordapp.com/attachments/1277532463547027477/1280436476261171220/bloc8.png?ex=66d96465&is=66d812e5&hm=dda541f8ed8f6dac614ce85d99c5e6bcc6120cea9c8f5f339709a7c761165511&'); 
  background-size: cover; 
  background-position: center;
}
.block9{
  background-image: url('https://firebasestorage.googleapis.com/v0/b/qorani-c5ac6.appspot.com/o/FCMImages%2FbackgroundNadia2.png?alt=media&token=43419624-9bae-4e31-a667-fa376dc04875'); 
  background-size: cover; 
  background-position: center;

}
.hero {
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: black;
  padding: 20px;
}

.nav-buttons .nav-button {
  margin: 0 40px;
}

.black-label {
  color: black; /* Couleur du texte du label */
}

.nav-buttons {
  color: black;
  font-weight: bold;
}

.mr-4 {
  margin-right: 60px; 
  margin-left: 50px;
  background-color: black; 
  padding: 10px; 
  border-radius: 5px;
  display: inline-block; 
}

.hero-btn {
  margin-right: 20px;
  color: white;
  background-color: #2ECC71;
}

.btn_telecharger {
  color: #2ECC71;
  border: 1px solid #2ECC71;
}

.qorani {
  font-weight: bold;
}

.custom-page {
  background-position: left bottom; /* Position de l'image de fond principale */
  position: relative; 
  background-color: white; 
  padding-top: 20px; 
  padding-bottom: 100px;
}

.hero-title2 {
  font-weight: bold;
  font-size: 20px;
}

.hero-title {
  color: black; 
  font-weight: bold; 
  margin-bottom: 16px;
  font-size: 50px;
}

.hero-text {
  color: black; 
  margin-bottom: 16px;
}

.button-group {
  display: flex;
  gap: 16px; 
  margin-bottom: 20px; 
}

.responsive-img {
  max-width: 100%;
  height: auto; 
}

.large-img {
  max-width: 350px; 
  height: auto; 
}

.large-img6 {
  max-width: 200px; 
  height: auto; 
  border-radius: 15px; 
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}


.green-button {
  background-color: #2ECC71; 
  color: white; 
  margin-left: 200px; 
}

.grey-border {
  border: 1px solid #ccc; 
  border-radius: 5px; /* Arrondi des coins */
  width: 400px; /* Ajustez cette valeur pour la largeur du champ */
  height: 50px; /* Ajustez cette valeur pour la hauteur du champ */
}

.no-shadow {
  box-shadow: none !important; /* Supprime toute ombre */
}

.footer-content {
  padding-top: 10px;
}

@media (max-width: 1024px) {
  .hero-title {
    font-size: 40px;
  }

  .mr-4 {
    margin-right: 30px; 
    margin-left: 20px;
  }

  .large-img {
    max-width: 300px; 
  }

  .green-button {
    margin-left: 100px;
  }

  .grey-border {
    width: 300px; 
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 30px;
  }

  .mr-4 {
    margin-right: 20px; 
    margin-left: 10px;
  }

  .large-img {
    max-width: 250px; 
  }

  .green-button {
    margin-left: 50px;
  }

  .grey-border {
    width: 250px; 
  }

  .nav-buttons {
    flex-direction: column;
    align-items: center;
  }

  .nav-button, .login-button {
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 24px;
  }

  .mr-4 {
    margin-right: 10px; 
    margin-left: 5px;
  }

  .large-img {
    max-width: 200px; 
  }

  .green-button {
    margin-left: 20px;
  }

  .grey-border {
    width: 200px; 
  }

  .button-group {
    flex-direction: column;
    align-items: center;
  }
}
</style>
